<template>
  <el-container>
    <el-aside width="613px">
      <el-container>
        <el-header height="50px">
          <el-form :inline="true">
            <el-form-item label="货号：">
              <el-input v-model="tableConfig.searchForm.data.ItemNo" placeholder="请输入货号"></el-input>
            </el-form-item>
            <el-form-item label="供应商：">
              <el-input v-model="tableConfig.searchForm.data.SupplierName" placeholder="请输入供应商"></el-input>
            </el-form-item>
            <el-form-item style="float: right">
              <el-button type="primary" @click="handleQuery()">查询</el-button>
              <el-button type="default" @click="reset()">重置</el-button>
              <el-dropdown @command="handleExport">
                <el-button type="primary" plain>
                  导出<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="ProductID">导出到货号</el-dropdown-item>
                  <el-dropdown-item command="SpecID">导出到规格</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
          </el-form>
        </el-header>
        <el-main>
          <grid-table ref="gridTable" :showIndexColumn="false" :showCheckboxColumn="false" :showLastEmptyColumn="false"
            :showPagination="true" :tableConfig="tableConfig" @handleRowClick="handleRowClick" :resizable="false">
          </grid-table>
        </el-main>
      </el-container>
    </el-aside>
    <el-main>
      <el-container v-if="detail.item != null">
        <el-header height="50px">
          <span class="span-spec-name">货号：{{ detail.item.ItemNo }}</span>
          <span class="span-spec-count">sku数量：{{ detail.details.length }}</span>
          <span class="span-spec-count">当前库存合计：{{ detail.item.Stock }}</span>
          <span class="span-spec-count">订单占用合计：{{ detail.item.OccupyStock }}</span>
          <span class="span-spec-count">剩余可用合计：{{ detail.item.LeftStock }}</span>
        </el-header>
        <el-main>
          <el-table :data="detail.details" :span-method="objectSpanMethod" v-loading="detail.loading" border
            ref="detailTable" height="100%" :cell-style="objectCellStyle">
            <el-table-column prop="Color" label="颜色" header-align="center" align="center" fixed width="150" />
            <el-table-column prop="Size" label="尺码" header-align="center" align="center" fixed width="70" />
            <el-table-column label="合计库存" header-align="center" align="center" width="180" fixed>
              <el-table-column prop="TotalStock" class-name="stock" header-align="center" align="center" label="当前"
                width="60" />
              <el-table-column prop="TotalOccupyStock" class-name="stock" header-align="center" align="center" label="占用"
                width="60" />
              <el-table-column prop="TotalLeftStock" class-name="left-stock" header-align="center" align="center"
                label="可用" width="60" />
            </el-table-column>
            <el-table-column v-for="item in warehouseList" :key="item.WID" :label="item.WName" header-align="center"
              align="center">
              <el-table-column :prop="'Stock' + item.WID" class-name="stock" header-align="center" align="center"
                label="当前" width="60" :formatter="formatter">
                <template slot-scope="{ row }">
                  {{ row['Stock' + item.WID] == undefined ? '-' : row['Stock' + item.WID] }}
                  <i class="el-icon-edit-outline" @click="showStockDialog(row, item.WID, item.WName)" title="微调库存" />
                </template>
              </el-table-column>
              <el-table-column :prop="'OccupyStock' + item.WID" class-name="stock" header-align="center" align="center"
                label="占用" width="60" :formatter="formatter" />
              <el-table-column :prop="'LeftStock' + item.WID" class-name="left-stock" header-align="center" align="center"
                label="可用" width="60" :formatter="formatter" />
            </el-table-column>
            <el-table-column :key="Math.random()" label=""></el-table-column>
          </el-table>
          <el-dialog width="450px" :title="'[' + editStock.WName + ']库存微调'" :visible="editStock.visible"
            :close-on-click-modal="false" @close="editStock.visible = false">
            <el-form label-width="70px" :model="editStock">
              <el-form-item label="货号：">{{ detail.item.ItemNo }}</el-form-item>
              <el-form-item label="规格：">{{ editStock.SpecName }}</el-form-item>
              <el-form-item label="库存：">
                <el-input v-model="editStock.Stock" v-format.float />
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="saveStock">保存</el-button>
              <el-button @click="editStock.visible = false">关闭</el-button>
            </div>
          </el-dialog>
        </el-main>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
import { submit, exportFile } from "@/api/base";
import dayjs from "dayjs";
export default {
  name: "stockIndex",
  data() {
    return {
      tableConfig: {
        url: "/api/wms/stock/getPageList",
        rowKey: "ProductID",
        searchForm: {
          data: {
            ItemNo: "",
            SupplierName: "",
          }
        },
        columns: [
          {
            label: "货号",
            prop: "ItemNo",
            type: "text",
            width: "178px"
          },
          {
            label: "当前库存",
            prop: "Stock",
            type: "text",
          },
          {
            label: "订单占用库存",
            prop: "OccupyStock",
            type: "text",
          },
          {
            label: "剩余可用库存",
            prop: "LeftStock",
            type: "text",
          }
        ],
      },
      detail: {
        loading: false,
        item: null,
        details: [],
        data: []
      },
      exportLoading: false,
      mergeObj: {}, // 用来记录需要合并行的下标
      mergeArr: ["Color"], // 表格中的列名
      warehouseList: [],
      editStock: {
        visible: false,
        Stock: 0,
        SpecName: '-'
      }
    };
  },
  mounted() {
    this.handleQuery();
    if (this.$store.state.routes.systemSetting.MultiWarehouse) {
      this.getWarehouses();
    }
  },
  methods: {
    async getWarehouses() {
      const { data } = await submit("/api/common/getWarehouseList");
      this.warehouseList = data ?? [];
    },
    reset() {
      this.tableConfig.searchForm.data.SupplierName = "";
      this.tableConfig.searchForm.data.ItemNo = "";
      this.handleQuery();
    },
    async handleQuery() {
      this.$refs.gridTable.fetchData();
      this.removeEditData();
    },
    async handleRowClick(row) {
      this.$refs.gridTable.$refs.gridTable.clearSelection();
      this.$refs.gridTable.$refs.gridTable.toggleRowSelection(row);
      this.detail.loading = true;//防止单个采购单太大或电脑性能问题导致太久没反应
      var param = { ProductID: row.ProductID };
      const { data } = await submit(`/api/wms/stock/getDetails`, param);
      this.detail.item = row;
      this.detail.details = data;
      this.getSpanArr();
      this.detail.loading = false;
    },
    formatter(row, column) {
      if (row[column.property] == undefined) {
        return '-';
      }
      return row[column.property];
    },
    objectCellStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property) {
        if (column.property.indexOf('Stock') > -1) {
          if (row[column.property] < 0) {
            return { color: '#F56C6C' };
          }
        }
      }
      return {};
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.property == 'Color') {
        if (this.mergeArr[rowIndex]) {
          return [this.mergeArr[rowIndex], 1];
        } else {
          return [0, 0];
        }
      }
    },
    getSpanArr() {
      let that = this;
      let itemIndex = 0;
      that.mergeArr = [];
      that.detail.details.forEach((item, index) => {
        if (index === 0 || (index > 0 && item.Color != that.detail.details[index - 1].Color)) {
          itemIndex = index;
          that.mergeArr.push(1);
        } else {
          that.mergeArr[itemIndex] += 1;
          that.mergeArr.push(0);
        }
      });
    },
    removeEditData() {
      this.detail.item = null;
      this.detail.details = [];
    },
    async handleExport(command) {
      this.exportLoading = true;
      const { data } = await exportFile('/api/wms/stock/export', { ...this.tableConfig.searchForm.data, ExportType: command });
      const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      const today = dayjs().startOf('day').format('YYYYMMDD');
      a.download = '商品库存_' + today + '.xls';
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
      this.exportLoading = false;
    },
    showStockDialog(row, wid, wname) {
      this.editStock.row = row;
      this.editStock.WID = wid;
      this.editStock.WName = wname;
      this.editStock.Stock = row['Stock' + wid] || 0;
      this.editStock.SpecName = row.SpecName;
      this.editStock.visible = true;
    },
    async saveStock() {
      await submit('/api/wms/stock/adjust', {
        ProductID: this.editStock.row.ProductID,
        SpecID: this.editStock.row.SpecID,
        WID: this.editStock.WID,
        Stock: this.editStock.Stock
      });
      let changeStock = parseInt(this.editStock.Stock) - this.editStock.row['Stock' + this.editStock.WID];
      this.detail.item.Stock += changeStock;
      this.detail.item.LeftStock += changeStock;
      this.editStock.row.TotalStock += changeStock;
      this.editStock.row.TotalLeftStock += changeStock;
      this.editStock.row['Stock' + this.editStock.WID] += changeStock;
      this.editStock.row['LeftStock' + this.editStock.WID] += changeStock;
      this.editStock.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .span_color_info {
    color: #9e9e9e;
    font-size: 12px;
  }

  .el-aside {
    .el-form {
      .el-select {
        width: 100px;
        margin-right: 5px;
      }

      .el-form-item__label {
        padding-right: 0;
      }

      .el-input__inner {
        width: 120px;
      }

      .el-dropdown {
        margin-left: 10px;
      }
    }
  }

  .el-main {
    .el-header {
      .span-spec-name {
        font-weight: bold;
        font-size: 20px;
      }

      .span-spec-count {
        margin-left: 20px;
        font-size: 16px;
      }
    }

    .el-main {

      .size-tag {
        height: 36px;
        line-height: 33px;
        margin: 6px 5px 3px 0px;
        float: left;

        .el-input--small .el-input__inner {
          height: 22px;
          line-height: 22px;
          padding: 0px;
          text-align: center;
        }
      }

      .tag-item:hover .icon-delete {
        display: block;
      }

      .span-delete {
        color: red;
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        margin-top: 15px;
      }

      .span-delete:hover {
        text-decoration: underline;
      }

      .icon-delete {
        background-color: red;
        border-radius: 10px;
        color: #fff;
        opacity: 0.8;
        float: left;
        cursor: pointer;
        display: none;
        margin-left: -15px;
      }

      .stock,
      .left-stock {
        .cell {
          color: inherit !important;
          padding: 0px !important;
        }
      }

      .stock {
        border-right: 0px !important;

        .cell {
          border-right: 1px dashed #d0d2d5;
        }
      }
    }
  }
}
</style>
